import React from 'react'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import PropTypes from 'prop-types'

import {
  Section,
  Hero,
  Container,
  Columns,
  Heading
} from 'react-bulma-components'

import Layout from '../../../components/layouts/index'
import SEO from '../../../components/seo'

import '../../styles/index.scss'
import './styles.scss'

class RussianGuruPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        className="is-paddingless"
        bodyInContainer={false}
        alignBody="flex-start"
        navbarIsFixed
      >
        <SEO
          title="RussianGuru - iOS app to help you learn the Russian grammar"
          url={this.props.location.href}
        />
        <Section className="is-paddingless">
          <Hero>
            <Hero.Body>
              <Container>
                <Columns breakpoint="tablet">
                  <Columns.Column
                    tablet={{
                      size: 'half'
                    }}
                    desktop={{
                      size: 'half'
                    }}
                  >
                    <Columns>
                      <Columns.Column size={3}>
                        <div className="app-logo">
                          <figure className="image is-96x96 is-marginless">
                            <Image
                              fixed={
                                this.props.data.russianGuruLogoSmall
                                  .childImageSharp.fixed
                              }
                            />
                          </figure>
                        </div>
                      </Columns.Column>
                    </Columns>
                    <div className="appPromo">
                      <Heading className="is-spaced">RussianGuru</Heading>
                      <Heading subtitle size={5} renderAs="h2">
                        Learn and practice Russian grammar. <br />
                        With RussianGuru you can master the Russian cases by
                        quizzing yourself on accusative, dative, genitive,
                        prepositional, and instrumental cases.
                      </Heading>
                      <a
                        className="itunes-link"
                        href="https://itunes.apple.com/us/app/russianguru/id1243733520?mt=8"
                        style={{
                          display: 'inline-block',
                          overflow: 'hidden',
                          background:
                            'url(//linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg) no-repeat',
                          width: 155,
                          height: 60,
                          backgroundSize: 'contain'
                        }}
                      />
                    </div>
                  </Columns.Column>
                  <Columns.Column
                    tablet={{
                      size: 'half'
                    }}
                    desktop={{
                      size: 'half'
                    }}
                  >
                    <div className="iphone-7">
                      <Image
                        className="iphone-7-mask"
                        fluid={
                          this.props.data.iphoneDeviceImage.childImageSharp
                            .fluid
                        }
                      />
                    </div>
                  </Columns.Column>
                </Columns>
              </Container>
            </Hero.Body>
          </Hero>
        </Section>
      </Layout>
    )
  }
}

RussianGuruPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object
}

export default RussianGuruPage
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    russianGuruLogoSmall: file(
      absolutePath: { regex: "/logoWhiteBack192x192.png/" }
    ) {
      childImageSharp {
        fixed(width: 96, height: 96) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    russianGuruScreenshot: file(
      absolutePath: { regex: "/russian-guru-screenshot.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 260) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    iphoneDeviceImage: file(
      absolutePath: { regex: "/russian-guru-in-iphone-screenshot.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 598) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
